import gql from "graphql-tag";
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_CATEGORY_HEADER = gql`
  query CategoryHeader($categoryId: String!, $path: String!) {
    category(id: $categoryId) {
      name
      isActive
      description
      longDescription
      image
      urlPath
      children {
        name
        urlPath
        isSmart
        id
        isFacet
        facetAttribute
      }
      breadcrumbs(path: $path) {
        name
        urlPath
        id
      }
      seo {
        title
        description
        h1
        canonical
      }
    }
  }
`;

export class CategoryHeaderQuery extends Query {
  static defaultProps = {
    query: GET_CATEGORY_HEADER,
  };
}
