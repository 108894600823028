import React from 'react';
import Helmet from 'react-helmet';
import {SiteMap as SiteMapLayout, PageHeader} from 'benlux-ecommerce-ui';

import {MenuQuery} from '../../../queries/MenuQuery';
import {CategoryHeaderQuery} from '../../../queries/CategoryHeaderQuery';

const SiteMap = () => (
  <MenuQuery>
    {({menu}) => {
      return (
        <>
          <Helmet>
            <title>Plan du site</title>
          </Helmet>
          <CategoryHeaderQuery
            variables={{
              categoryId: menu[0].id,
              path: menu[0].urlPath,
            }}
          >
            {({ category, loading }) => {
              if (loading) {
                return <p>Chargement principal...</p>;
              }

              return (
                <>
                  <PageHeader
                    image={category.image}
                    title={'Plan du site'}
                    description={category.description}
                  />
                  <SiteMapLayout categories={menu}/>
                </>
              )
            }}
          </CategoryHeaderQuery>
        </>
      );
    }}
  </MenuQuery>
);

export default SiteMap;
